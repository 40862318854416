<!--
健康服务
-->
<template>
  <div class="mainBox">
    <div class="shipTitle">
      <div class="changTitle">
        <div
          class="changeItme"
          :class="changeIndex == 1 ? 'changeItmeActive' : ''"
          @click="changeIndex = 1"
        >
          健康体检
        </div>
      </div>
    </div>
    <!--健康服务-->
    <div class="shipMain serviceMainBg healthInfoBox">
      <div class="healthCom">
        <div class="healthInfoTxt">
          <div style="margin-bottom: 4px">健康值</div>
          <div>87</div>
        </div>

        <div class="healthIconBg bgPositong1">
          <span class="bgtxt1">68.5</span>
          <span class="bgtxt2">Kg</span>
        </div>

        <div class="healthIconBg bgPositong2">
          <span class="bgtxt1">179</span>
          <span class="bgtxt2">cm</span>
        </div>
      </div>

      <div class="healthList">
        <div class="h_items">
          <div class="h_icon">
            <img
              src="@/assets/img/serviceManagement/hlist_icon.png"
              alt=""
              class="hlist_icon"
            />
            <div>BMI</div>
          </div>
          <div class="h_nums">20.3</div>
          <div class="h_cont">kg/㎡</div>
        </div>
        <div class="h_items">
          <div class="h_icon">
            <img
              src="@/assets/img/serviceManagement/hlist_icon1.png"
              alt=""
              class="hlist_icon"
            />
            <div>BMI</div>
          </div>
          <div class="h_nums">20.3</div>
          <div class="h_cont">kg/㎡</div>
        </div>
        <div class="h_items">
          <div class="h_icon">
            <img
              src="@/assets/img/serviceManagement/hlist_icon2.png"
              alt=""
              class="hlist_icon"
            />
            <div>BMI</div>
          </div>
          <div class="h_nums">20.3</div>
          <div class="h_cont">kg/㎡</div>
        </div>
        <div class="h_items">
          <div class="h_icon">
            <img
              src="@/assets/img/serviceManagement/hlist_icon3.png"
              alt=""
              class="hlist_icon"
            />
            <div>BMI</div>
          </div>
          <div class="h_nums">20.3</div>
          <div class="h_cont">kg/㎡</div>
        </div>
      </div>

      <div class="servicesMainBg">
        <div class="ser_info">
          <div class="in_title">血脂四项</div>
          <div class="in_tip">
            <div class="tip_num">1</div>
            <div class="tip_info">项需重点关注</div>
          </div>
        </div>
        <div class="info_list">
          <div class="ser_list">
            <div class="ser_title">
              <div class="t_icon"></div>
              <div class="t_text">
                <span class="t_text_name">总胆固醇</span>
                <span class="t_text_count">（mmol/L）</span>
              </div>
              <div class="t_line">
                <div class="t_line_pos">
                  <div class="t_line_pos_info">
                    <div class="t_line_pos_infoy"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ser_list">
            <div class="ser_title">
              <div class="t_icon"></div>
              <div class="t_text">
                <span class="t_text_name">总胆固醇</span>
                <span class="t_text_count">（mmol/L）</span>
              </div>
              <div class="t_line">
                <div class="t_line_pos">
                  <div class="t_line_pos_info">
                    <div class="t_line_pos_infoy"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ser_list">
            <div class="ser_title">
              <div class="t_icon"></div>
              <div class="t_text">
                <span class="t_text_name">总胆固醇</span>
                <span class="t_text_count">（mmol/L）</span>
              </div>
              <div class="t_line">
                <div class="t_line_pos">
                  <div class="t_line_pos_info">
                    <div class="t_line_pos_infoy"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ser_list">
            <div class="ser_title">
              <div class="t_icon"></div>
              <div class="t_text">
                <span class="t_text_name">总胆固醇</span>
                <span class="t_text_count">（mmol/L）</span>
              </div>
              <div class="t_line">
                <div class="t_line_pos">
                  <div class="t_line_pos_info">
                    <div class="t_line_pos_infoy"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      timeData: "",
      changeIndex: 1,
    };
  },
  created() {},

  mounted: function () {},
  destroyed: function () {},
  computed: {},
  watch: {},
};
</script>
<style lang="less" scoped>
.serviceMainBg {
  background: url("../../assets/img/serviceManagement/serviceBg.png") no-repeat;
  background-size: cover;
}
.healthInfoBox {
  position: relative;
}
.healthCom {
  background: url("../../assets/img/serviceManagement/healthCom.png") no-repeat;
  background-size: cover;
  width: 195px;
  height: 195px;
  position: absolute;
  top: 92px;
  left: 80px;
}
.healthInfoTxt {
  position: absolute;
  top: 87px;
  right: 34px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #476bfe;
}
.healthIconBg {
  position: absolute;
  padding-left: 44px;
  text-align: center;
  width: 127px;
  height: 44px;
  line-height: 44px;
  .bgtxt1 {
    font-size: 20px;
    font-weight: 400;
    color: #476bfe;
    margin-right: 5px;
  }
  .bgtxt2 {
    font-size: 16px;
    font-weight: 400;
    color: #476bfe;
  }
}
.bgPositong1 {
  top: 18px;
  right: -74px;
  background: url("../../assets/img/serviceManagement/iconBg.png") no-repeat;
  background-size: cover;
}
.bgPositong2 {
  top: 148px;
  left: -47px;
  background: url("../../assets/img/serviceManagement/iconBg2.png") no-repeat;
  background-size: cover;
}
.healthList {
  position: absolute;
  bottom: 102px;
  left: 77px;
}
.h_items {
  display: flex;
  align-items: center;
  padding-bottom: 17px;
  border-bottom: 1px solid rgba(71, 107, 254, 0.26);
  padding-top: 15px;
}
.h_items::after {
}
.h_icon {
  display: flex;
  align-items: center;
  margin-right: 55px;
}
.hlist_icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.h_nums {
  font-size: 18px;
  font-weight: 400;
  color: #476bfe;
  margin-right: 30px;
}
.h_cont {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}
.servicesMainBg {
  background: url("../../assets/img/serviceManagement/boxImg.png") no-repeat;
  position: absolute;
  top: 57px;
  right: 0px;
  width: 718px;
  height: 552px;
  background-size: cover;
  padding-left: 317px;
  padding-top: 104px;
  margin-top: 40px;
}
.ser_info {
  display: flex;
  align-items: center;
}
.in_title {
  font-size: 22px;
  font-weight: 500;
  color: #333333;
  margin-right: 35px;
}
.in_tip {
  display: flex;
  align-items: center;
}
.tip_num {
  min-width: 18px;
  height: 18px;
  background: rgba(216, 39, 39, 0.14);
  border-radius: 2px 2px 2px 2px;
  color: #d82727;
  line-height: 18px;
  font-size: 14px;
  border: 1px solid #d82727;
  text-align: center;
  margin-right: 5px;
}
.tip_info {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
.info_list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 42px;
  justify-content: space-between;
}
.ser_list {
  width: 150px;
  margin-bottom: 87px;
}
.ser_title {
  position: relative;
  .t_icon {
    position: absolute;
    top: 5px;
    left: -22px;
    width: 12px;
    height: 12px;
    border: 2px solid #fb4d4d;
    border-radius: 50%;
  }
  .t_text {
    margin-bottom: 45px;
  }
  .t_text_name {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
  .t_text_count {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
  .t_line {
    width: 150px;
    height: 6px;
    background: #e2e2e2;
    border-radius: 3px 3px 3px 3px;
    position: relative;
  }
  .t_line_pos {
    width: 90px;
    height: 6px;
    background: linear-gradient(
      270deg,
      rgba(71, 107, 254, 0) 0%,
      rgba(71, 107, 254, 0.74) 49%,
      rgba(101, 130, 249, 0) 100%
    );
    border-radius: 3px 3px 3px 3px;
    position: absolute;
    top: 0;
    left: 30px;
    .t_line_pos_info {
      width: 12px;
      height: 12px;
      background: #476bfe;
      box-shadow: 0px 1px 6px 1px rgba(49, 49, 49, 0.16);
      opacity: 1;
      border: 3px solid #ffffff;
      position: absolute;
      top: -2px;
      left: 30px;
      border-radius: 50%;
    }
  }
}
</style>
